import { timeFormat, numberFormat } from "@custom";
import router from "@/router";
import {recordTypelist} from './recordTypelist'
import self from '@/main.js'
// 智能门锁 tableColumn
export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 60
    },
    {
        prop: "voltage",
        label: "电压",
    },
    {
        prop: "rssi",
        label: "信号强度",
    },
    {
        prop: "iotTag",
        label: "门锁关键词",
    },
    {
        prop: "address",
        label: "地址",
    },
];

export const tableColumnIndex = [
    {
        prop: "waterNum",
        label: "水表号",
        width: 150,
    },
    {
        prop: "water",
        label: "水表最后读数",
        width: 150,
    },
    {
        label: "水表最后抄表时间",
        width: 150,
        render: (h, {row}) => {
            let {lastWaterTime} = row;
            lastWaterTime = lastWaterTime ? timeFormat(new Date(lastWaterTime), "yyyy-MM-dd HH:mm:ss") : "近期未抄过表";
            return h("span", lastWaterTime);
        }
    },
    {
        prop: "electNum",
        width: 150,
        label: "电表号",
    },
    {
        prop: "elect",
        label: "电表最后读数",
        width: 150,
    },
    {
        label: "电表最后抄表时间",
        width: 150,
        render: (h, {row}) => {
            let {lastElectTime} = row;
            lastElectTime = lastElectTime ? timeFormat(new Date(lastElectTime), "yyyy-MM-dd HH:mm:ss") : "近期未抄过表";
            return h("span", lastElectTime);
        }
    },
    {
        prop: "lockId",
        label: "门锁号",
    },
    /*{
        prop: "",
        width:150,
        label: "最后开锁时间",
    },*/
];
export const tableColumnWater = [
    {
        type: "index",
        label: "编号",
        width: 100,
    },
    {
        prop: "typeName",
        label: "抄表类型",
        width: 100,
    },
    {
        prop: "lastTime",
        label: "上次抄表时间",
        render: (h, {row}) => {
            const timeStamp = !row.lastTime ? "" : timeFormat(new Date(row.lastTime), 'yyyy-MM-dd');
            return h("span", timeStamp);
        },
    },
    {
        prop: "lastWater",
        label: "上次抄表数",
    },
    {
        prop: "createTime",
        label: "本次抄表时间",
        render: (h, {row}) => {
            const timeStamp = !row.createTime ? "" : timeFormat(new Date(row.createTime), 'yyyy-MM-dd');
            return h("span", timeStamp);
        },
    },
    {
        prop: "water",
        label: "本次抄表数",
    },
    {
        prop: "priceWater",
        label: "单价(元/m³)",
        render: (h, {row}) => {
            const timeStamp = !row.priceWater ? "0.00" : numberFormat(row.priceWater / 100);
            return h("span", timeStamp);
        },
    },
    {
        prop: "amountWater",
        label: "金额(元)",
        render: (h, {row}) => {
            const timeStamp = !row.amountWater ? "0.00" : numberFormat(row.amountWater / 100);
            return h("span", timeStamp);
        },
    },
    {
        prop: "createTime",
        label: "操作时间",
        width: 150,
        render: (h, {row: {updateTimestamp}}) => {
            const timeStamp = !updateTimestamp ? "" : timeFormat(new Date(updateTimestamp), 'yyyy-MM-dd HH:mm:ss');
            return h("span", timeStamp);
        },
    },
];

export const tableColumnElect = [
    {
        type: "index",
        label: "编号",
        width: 100
    },
    {
        prop: "typeName",
        label: "抄表类型",
        width: 100
    },
    {
        prop: "lastTime",
        label: "上次抄表时间",
        render: (h, {row}) => {
            const timeStamp = !row.lastTime ? "" : timeFormat(new Date(row.lastTime), 'yyyy-MM-dd');
            return h("span", timeStamp);
        }
    },
    {
        prop: "lastElect",
        label: "上次抄表数"
    },
    {
        prop: "createTime",
        label: "本次抄表时间",
        render: (h, {row}) => {
            const timeStamp = !row.createTime ? "" : timeFormat(new Date(row.createTime), 'yyyy-MM-dd');
            return h("span", timeStamp);
        }
    },
    {
        prop: "elect",
        label: "本次抄表数"
    },
    {
        prop: "priceElect",
        label: "单价(元/Kw.h)",
        render: (h, {row}) => {
            const timeStamp = !row.priceElect ? "0.00" : numberFormat(row.priceElect / 100);
            return h("span", timeStamp);
        }
    },
    {
        prop: "amountElect",
        label: "金额(元)",
        render: (h, {row}) => {
            const timeStamp = !row.amountElect ? "0.00" : numberFormat(row.amountElect / 100);
            return h("span", timeStamp);
        }
    },
    {
        prop: "createTime",
        label: "操作时间",
        width: 150,
        render: (h, {row: {updateTimestamp}}) => {
            const timeStamp = !updateTimestamp ? "" : timeFormat(new Date(updateTimestamp), 'yyyy-MM-dd HH:mm:ss');
            return h("span", timeStamp);
        },
    },
];

export function tableColumnLockId(_this) {
  return   [
    {
        type: "index",
        label: "编号",
        width: 100
    },
    {
        prop: "leasorName",
      label: "承租人",
      render: (h, {row}) => {
        const {leasorName} = row;
        return h("el-link", {
            props: {type: "primary", underline: false},
            on: {
                click() {
                  _this.$router.push({name: "tenant-management", params: {data: {leasorName}}});
                  // console.log(self);
                }
            }
        }, leasorName);
    }

    },
    {
        prop: "apartmentName",
      label: "房间地址",
      render: (h, {row}) => {
        const {apartmentName} = row;
        return h("el-link", {
            props: {type: "primary", underline: false},
            on: {
                click() {
                    _this.$router.push({name: "house-resource", query: {data: {apartmerntName:apartmentName}}});
                }
            }
        }, apartmentName);
    }
    },
      {
          prop: "phone",
          label: "手机号",
          width: 100
      },
      {
        prop: "stratDate",
        label: "有效开始时间",
        render: (h, {row}) => {
            return timeFormat(new Date(row.stratDate));
        },
    },
    {
        prop: "endDate",
        label: "有效结束时间",
        render: (h, {row}) => {
            return timeFormat(new Date(row.endDate));
        },
    },
    {
        prop: "state",
        label: "状态",
        render: (h, {row}) => {
            return h("span", row.state === 66 ? "可用" : "不可用");
        }
    },
    {
        prop: "createTime",
        label: "操作时间",
        width: 150,
        render: (h, {row: {timestamp}}) => {
            const timeStamp = !timestamp ? "" : timeFormat(new Date(timestamp), 'yyyy-MM-dd HH:mm:ss');
            return h("span", timeStamp);
        },
    },
   // 张晓瑜修改密码类型
   {
    prop: "typeName",
    label: "密码类型",
    width: 150,
},
];

}

export const tableColumnUnlockRecord = [
    {
        type: "index",
        label: "编号",
        width: 100
    }, {
        prop: "lockDate",
        label: "开锁时间",
        render: (h, {row}) => {
            return timeFormat(new Date(row.lockDate), "yyyy-MM-dd HH:mm:ss");
        },
    }, {
        prop: "logAlertaaa",
        label: "开锁方式",
        render: (h, {row}) => {
            return  recordTypelist[row.recordTypeFromLock]
            // if(row.recordTypeFromLock === 1) return '蓝牙开锁'
            // if(row.recordTypeFromLock === 4) return '密码开锁成功'
            // if(row.recordTypeFromLock === 5) return '在锁上修改密码'
            // if(row.recordTypeFromLock === 6) return '在锁上删除一个密码'
            // if(row.recordTypeFromLock === 7) return '密码开锁失败，未知密码'
            // if(row.recordTypeFromLock === 8) return '清空密码'
        },
    }
];
export const tableColumnUnlockRecordTWO = [
    {
        type: "index",
        label: "编号",
        width: 100
    },
     {
        prop: "lockType",
        label: "开锁时间",
        render: (h, {row}) => {
            const modifiedTime = row.updateTime * 1000;
            return timeFormat(new Date(modifiedTime), "yyyy-MM-dd HH:mm:ss");
        },
    },
    {
        prop: "logAlert",
        label: "开锁方式",
    }
];

// 2023/03/31 张晓瑜新增开锁记录(针对已出租房源，超过7天无门锁使用记录时系统进行异常提醒)
export const tableColumnUnlockTwoRecord = [
    {
        type: "index",
        label: "编号",
        width: 100
    }, {
        prop: "time",
        label: "开锁时间",
        render: (h, {row}) => {
            return timeFormat(new Date(row.time), "yyyy-MM-dd HH:mm:ss");
        },
    }, {
        prop: "typeDesc",
        label: "开锁方式"
    }
];



export const tableoperatingRecord = [
    {
        type: "index",
        label: "编号",
        width: 60
    },
    {
        prop: "time",
        label: "操作时间",
        width: 180,
        render: (h, {row}) => {
            return timeFormat(new Date(row.operatingTime), "yyyy-MM-dd HH:mm:ss");
        },
    },
    {
        prop: "operator",
        label: "操作人",


    },
    {
        prop: "type",
        label: "操作记录",


    },
    {
        prop: "lessee",
        label: "承租人",

    },
    {
        prop: "roomAddress",
        label: "房间地址",
        width: 180,
    },
    {
        prop: "startingTime",
        label: "有效开始时间",
        width: 180,
    },
    {
        prop: "endTime",
        label: "有效结束时间",
        width: 180,
    },
    {
        prop: "passwordType",
        label: "密码类型",

    },

];

export function tableWorkOrderColumnjilu(that) {
    // 审批密码操作记录
    return [
        {
            prop: "handleUserName",
            label: "处理人",
            width: 150
        },
        // {
        //     label: "处理人角色",
        //     width: 150,
        //     prop: 'handleRole'
        // },
        {
            label: "操作",
            width: 150,
            prop:'operateType',
        },

        {
            label: "操作时间",
            width: 150,
            // prop:'handleDate',
            render: (h, {row}) => {
                const {handleDate} = row;
                if(handleDate === null) return ''
                return handleDate ? timeFormat(new Date(handleDate), "yyyy-MM-dd HH:mm:ss") : "";
            }
        },
        {
            prop: "rejectReason",
            label: "驳回原因",
            showOverflowTooltip: true,
        },
    ];
}
